import React from 'react';
import Slider from 'react-slick';
import './Testimonials.css'
const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: dots => (
      <div>
        <ul className="flex justify-center mt-4">{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
    ),
  };

  const testimonials = [
    {
      quote: 'I thought it was impossible to make a website as beautiful as our product, but TheTechBus proved me wrong.',
      author: 'James Lee',
      position: 'CFO of Acme Co.',
      avatar: require('../Assets/testimonial1.png'), // Replace with your image
    },
    {
      quote: 'I\'ve never met a web developer who cares about success like TheTechBus does.',
      author: 'Richel leblanch',
      position: 'COO of DEF Corp.',
      avatar: require('../Assets/testi1.png'), // Replace with your image
    },
    // Add more testimonials as needed
  ];

  return (
    <div className="max-w-6xl mx-auto py-16 " id='about'>
      <div className="text-center mb-8">
        <h3 className="testimonial-button">Testimonials</h3>
        <h2 className="text-4xl font-bold text-gray-800">What Others Say About our Services</h2>
      </div>

      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="flex flex-col items-center justify-center text-center px-4">
            <div className="relative bg-white shadow-lg rounded-lg p-8 border border-yellow-200" style={{minHeight:350}}>
              <p className="text-gray-700 italic text-lg mb-4">{testimonial.quote}</p>
              <div className="flex items-center justify-center">
                <img
                  src={testimonial.avatar}
                  alt={testimonial.author}
                  className="w-16 h-16 rounded-full object-cover border-2 border-gray-300"
                />
              </div>
              <div className="mt-4">
                <h4 className="font-semibold text-gray-800">{testimonial.author}</h4>
                <p className="text-gray-500">{testimonial.position}</p>
              </div>
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-3">
                <div className="w-6 h-6 bg-yellow-200 rounded-full"></div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
