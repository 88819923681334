import React from 'react';
import Slider from 'react-slick';
import './ClientScroller.css'; // Ensure to import your CSS file

// Images for the carousel
import client1 from '../Assets/logo-1.png';
import client2 from '../Assets/logo-2.png';
import client3 from '../Assets/logo-3.png';
import client4 from '../Assets/logo-4.png';

const ClientScroller = () => {
    // Slick slider settings
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Show 3 slides at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000, // Adjust speed as necessary
        cssEase: 'linear'
    };

    return (
        <section className="client-scroller">
            <Slider {...settings}>
                <img src={client1} alt="CSS" className="scroller-image-client" />
                <img src={client2} alt="Docker" className="scroller-image-client" />
                <img src={client3} alt="Figma" className="scroller-image-client" />
                <img src={client4} alt="HTML" className="scroller-image-client" />
                {/* Repeat images to ensure seamless looping */}
                <img src={client1} alt="CSS" className="scroller-image-client" />
                <img src={client2} alt="Docker" className="scroller-image-client" />
                <img src={client3} alt="Figma" className="scroller-image-client" />
                <img src={client4} alt="HTML" className="scroller-image-client" />
            </Slider>
        </section>
    );
};

export default ClientScroller;
