import React from 'react';
import { FaLinkedin, FaInstagram  } from 'react-icons/fa';
import {CiMail} from 'react-icons/ci' 
// Import social media icons
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h4>Company</h4>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#projects">Project</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
      </div>
      {/* Uncomment and update services section if needed */}
      {/* <div className="footer-section">
        <h4>Services</h4>
        <ul>
          <li><a href="#">Web Development</a></li>
          <li><a href="#">App Development</a></li>
          <li><a href="#">Backend Development</a></li>
          <li><a href="#">Quality Assurance</a></li>
          <li><a href="#">No-code Development</a></li>
          <li><a href="#">Product Designing</a></li>
        </ul>
      </div> */}
      <div className="footer-section">
        <h4>Social Media</h4>
        <ul>
          <li><a href="https://www.linkedin.com/company/thetechbusconnects"><FaLinkedin className="social-icon" /> LinkedIn</a></li>
          <li><a href="https://www.instagram.com/the_tech_bus?igsh=MXAxd3BqMXhzenRqOQ%3D%3D&utm_source=qr"><FaInstagram className="social-icon" /> Instagram</a></li>
          {/* <li><a href="#"><FaFacebook className="social-icon" /> Facebook</a></li> */}
        </ul>
      </div>
      <div className="footer-section">
        <h4>Contact</h4>
        <ul>
          <li><a href="mailto:techdomsolution@gmail.com">support@thetechbus.com</a></li>
          <li style={{textAlign:"left"}} className='flex'> <CiMail className="social-icon" />+91 - 85533 88439</li>
          {/* <li style={{textAlign:"left"}} className='flex'><FaPhoneAlt className="social-icon" />+91 - 81209 17874</li> */}
        </ul>
      </div>
      <div className="footer-banner">
        <h4>The Tech Bus</h4>
        <p>Join us in crafting customized solutions that drive your business forward.</p>
      </div>
    </footer>
  );
};

export default Footer;
