import React from 'react';
import Slider from 'react-slick';
import './TechScroller.css'; // Ensure to import your CSS file

// Images for the carousel
import tech1 from '../Assets/css.svg';
import tech2 from '../Assets/docker.svg';
import tech3 from '../Assets/figma.svg';
import tech4 from '../Assets/html.svg';
import tech5 from '../Assets/js.svg';
import tech6 from '../Assets/mongo.svg';
import tech7 from '../Assets/ts.svg';

const TechScroller = () => {
    // Slick slider settings
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Adjust to fit your needs
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000, // Adjust speed as necessary
        cssEase: 'linear'
    };

    return (
        <section className="tech-scroller">
            <Slider {...settings}>
                <img src={tech1} alt="CSS" className="scroller-image" />
                <img src={tech2} alt="Docker" className="scroller-image" />
                <img src={tech3} alt="Figma" className="scroller-image" />
                <img src={tech4} alt="HTML" className="scroller-image" />
                <img src={tech5} alt="JavaScript" className="scroller-image" />
                <img src={tech6} alt="MongoDB" className="scroller-image" />
                <img src={tech7} alt="TypeScript" className="scroller-image" />
                {/* Repeat images to ensure seamless looping */}
                <img src={tech1} alt="CSS" className="scroller-image" />
                <img src={tech2} alt="Docker" className="scroller-image" />
                <img src={tech3} alt="Figma" className="scroller-image" />
                <img src={tech4} alt="HTML" className="scroller-image" />
                <img src={tech5} alt="JavaScript" className="scroller-image" />
                <img src={tech6} alt="MongoDB" className="scroller-image" />
                <img src={tech7} alt="TypeScript" className="scroller-image" />
            </Slider>
        </section>
    );
};

export default TechScroller;
