import React from "react";
import "./ImpactSection.css";

const ImapctSection = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
      <section className="impact-section">
        <div className="impact-section__blobs">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h2 className="impact-title">IMPACT</h2>
        <div className="impact-stats">
          <div className="stat">
            <h3>#24</h3>
            <p>Completed Projects</p>
          </div>
          <div className="stat">
            <h3>95%</h3>
            <p>Client Retention Rate</p>
          </div>
          <div className="stat">
            <h3>10+</h3>
            <p>Diverse Industries</p>
          </div>
          <div className="stat">
            <h3>5+</h3>
            <p>Global Clients</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImapctSection;
